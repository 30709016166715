<template>
    <Navbar/>
   <!-- Hero Area -->

    <div class="hero-area banner-2 light-bg-1" id="home">                   
            <div class="hero-area-inner">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 wow fadeInUp animated hero-name-layer" data-wow-delay=".2s">
                            <div class="section-title">
                                <h1 class="text-dark hero-text-above">{{intro.name}}</h1>                                
                            </div>                            
                        </div>  
                        <div class="col-lg-5">
                            <div class="hero-author-img">
                                <img :src="intro.image" alt="">
                            </div>
                            <div class="banner-author-bg">
                                <img src="/assets/img/banner-author-bg.png" alt="">
                            </div>
                        </div>                      
                    </div>
                </div>                
            </div>            
    </div>


    <!-- About Section -->

    <div id="about" class="about-section about-two section-padding gray-bg-2">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-12 wow fadeInUp animated" data-wow-delay="100ms">
                    <div class="about-left">
                        <div class="repeat-grid">
                            <img :src="about.image" alt="">
                        </div>
                        <div class="section-title">
                            <h6 class="text-gray-2">About gharb <span>.....</span> </h6>
                        </div>
                        <p class="text-gray">{{about.description}} </p>
                        <p class="text-gray">open 11:00-21:00 <br> closed sundays</p>                        
                        <p class="text-gray">{{about.address}}</p>                        
                        <p class="text-gray">{{about.contact}}</p>                        
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-12 wow fadeInDown animated" data-wow-delay="200ms">
                    <div class="about-right">
                        <div class="map-wrap">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.915554066749!2d139.65190415034127!3d35.654452138951456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f38ef98ded8b%3A0x79dfa5b76e225e49!2z44Kr44OV44Kn44Ks44Or44OW!5e0!3m2!1sja!2sjp!4v1681302011801!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
        <div class="about-dot-shape">
            <img src="/assets/img/about-dot-shape.png" alt="">
        </div>
    </div>


    <!-- Service Section  -->

    <div id="services" class="service-section service-two section-padding pt-0">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="section-title">
                        <h6 class="text-gray-2">Menu <span>.....</span> </h6>
                        <h2 class="text-dark">What We Offer</h2>                            
                    </div>
                </div>
            </div>
            <div class="row gx-5">
                <div class="col-xl-4 col-lg-4 col-md-6 col-12 wow fadeInLeft animated" :data-wow-delay="(i+1)*100+'ms'" v-for="(service,i) in services" :key="service.id">
                    <div class="single-service-item">
                        <div class="service-inner">
                            <div class="service-icon">
                                <img :src="service.icon" alt="">
                            </div>
                            <div class="service-content">
                                <h4>{{service.name}}</h4>
                                <p v-html="service.description.split('\n').join('<br>')"></p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

 
    <!-- Portfolio Section  -->
    <div id="project" class="portfolio-section portfolio-two section-padding gray-bg">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="section-title">
                        <h6 class="text-gray-2">Instagharb <span>.....</span> </h6>
                    </div>
                </div>
                <!--
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <ul class="port-menu recent text-center">
						<li data-filter="*" class="active">All Works</li>
						<li data-filter=".design">Website</li>
						<li data-filter=".brand">Apps</li>
						<li data-filter=".illustration">Branding</li>
						<li data-filter=".print">illustration</li>
					</ul>
                </div>
                -->
            </div>
            <div class="row">
				<div class="col-lg-12">
					<div class="portfolio-list recent">
						
                            <div  :class="'portfolio-bg-wrap '+portfolio.categories"  v-for="portfolio in portfolios" :key="portfolio.id" :style="'background-image:url('+portfolio.background_image+')'">
                                <div class="portfolio-details">
                                   <h4 class="portfolio-title"><a href="#">{{portfolio.name}}</a></h4>
                                    <p>{{portfolio.type}}</p>
                                </div>							
                            </div>                         
					</div>
				</div>
			</div>
        </div>
    </div>

    <!-- Blog Section  -->

    <div id="blog" class="blog-section section-padding dark-bg-1">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="section-title">
                        <h6>News <span>.....</span> </h6>
                        <h4>gharbからのお知らせ</h4>         
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-12 wow fadeInLeft animated" :data-wow-delay="(i+1)*100+'ms'" v-for="(blog,i) in blogs.slice(0,3)" :key="blog.id">
                    <div class="single-blog-item">
                        <div class="blog-thumb">
                            <img :src="blog.image" alt="">
                        </div>
                        <div class="blog-content">
                            <div class="blog-meta">
                                <p>{{blog.date}}</p>
                            </div>
                            <div class="blog-title">
                                <h4><router-link :to="{ name:'BlogDetails', params:{id: blog.id} }">{{blog.title}}</router-link></h4>
                            </div>
                        </div>
                    </div>
                </div>
                <!--
            <h6><router-link :to="{ name:'Blogs'}"><span>View All News</span></router-link> </h6>
            -->
            </div>
        </div>
    </div>

    <!-- Contact Section  -->
<!--
    <div id="contact" class="contact-section contact-two section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="section-title">
                        <h6 class="text-gray-2">Contact <span>.....</span> </h6>
                    </div>
                </div>
            </div>
            <div class="row mt-60">
                <div class="col-xl-8 col-lg-8 col-md-6 col-12 wow fadeInUp animated" data-wow-delay="100ms">
                    <div class="contact-form-wrap">
                        <div class="form">
                            <div class="row">
                                <div class="col-12">
                                    <input type="text" placeholder="お名前" v-model="name">
                                </div>
                                <div class="col-md-6">
                                    <input type="phone" placeholder="お電話番号" v-model="phone">
                                </div>
                                <div class="col-md-6">
                                    <input type="email" placeholder="E-mail" v-model="email">
                                </div>
                                <div class="col-12">
                                    <textarea name="message" id="message" cols="30" rows="10" placeholder="お問い合わせ内容" v-model="message"></textarea>
                                </div>
                                <div class="col-12">
                                    <input class="main-btn" type="button" value="送信する" v-on:click="submitForm">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-12 wow fadeInRight animated" data-wow-delay="200ms">
                    <div class="about-right">
                        <div class="repeat-grid">
                            <img :src="about.image" alt="">
                        </div>
                        <div class="section-title">
                            <h6 class="text-gray-2">Reservation</h6>
                        </div>
                        <p class="text-gray">ご予約はフォームよりお気軽にお問い合わせください。                            
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
-->

    <Footer/>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import ClientReviews from '@/components/ClientReviews.vue'

import axios from 'axios'

export default {
  name: 'Home',
  components: {
    Navbar,Footer,ClientReviews
  },

  data(){
    return {
        services : [],
        educations : [],
        skills : [],
        experiences : [],
        portfolios : [],
        about: [],
        blogs : [],
        intro : [],
        home : ''
    }
  },
  async mounted(){

    //setting active home as Home to local storage
    localStorage.setItem("home","Home");

    //setting active home as Home to vue-store
    this.$store.commit("setHome", "Home");
    
    //getting active home from vue-store
    this.home = this.$store.state.home;

    //Isotope Filter
	$(".port-menu li").on("click", function () {
		var selector = $(this).attr("data-filter");

		$(".port-menu li").removeClass("active");

		$(this).addClass("active");

		$(".portfolio-list").isotope({
			filter: selector,
			percentPosition: true,
		});
	});
       
    let fetchedData = await axios.get("/assets/data/services.json");
    this.services = fetchedData.data.services;
        
    let fetchedEducationData = await axios.get("/assets/data/educations.json");
    this.educations = fetchedEducationData.data.educations;

    let fetchedSkillData = await axios.get("/assets/data/skills.json");
    this.skills = fetchedSkillData.data.skills;

    let fetchedExperienceData = await axios.get("/assets/data/experiences.json");
    this.experiences = fetchedExperienceData.data.experiences;

    let fetchedPortfolioData = await axios.get("/assets/data/portfolios.json");
    this.portfolios = fetchedPortfolioData.data.portfolios;

    let fetchedAboutData = await axios.get("/assets/data/about.json");
    this.about = fetchedAboutData.data.about;

    let fetchedBlogsData = await axios.get("/assets/data/blogs.json");
    this.blogs = fetchedBlogsData.data.blogs;

    let fetchedIntroData = await axios.get("/assets/data/home.json");
    this.intro = fetchedIntroData.data.intro;
    
  }
}
</script>
