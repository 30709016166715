<template>
    <Navbar/>
 <!-- Breadcrumb Area -->

    <div class="breadcroumb-area dark-bg-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="breadcroumb-title">
                        <h1 class="text-white">{{currentBlog.title}}</h1>
                        <div class="blog-meta">
                            <p>{{currentBlog.date}} <span>Posted By {{currentBlog.author}}</span></p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

    <!-- Blog Details  -->

    <div class="single-blog-wrapper section-padding dark-bg-2">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="blog-content-wrap">
                        <p>{{content.para1}}
                        </p>
                        <p>{{content.para2}}                
                        </p>
                        <div class="blog-thumb">
                            <img :src="content.image" alt="">
                        </div>
                        <p>
                            {{content.para3}}
                        </p>
                        <div class="blockquote-area">
                            <p >{{content.quote}}
                            </p>
                            <div class="quote-sign">
                                <img src="/assets/img/blog-left-quotes-sign.png" alt="">
                            </div>
                        </div>
                        <div class="blog-content-one">
                            <h3>{{content.heading1}}</h3>
                            <p>{{content.heading1_para1}}
                            </p>
                            <p>
                               {{content.heading1_para2}}
                            </p>
                        </div>
                        <div class="blog-list-wrap">
                            <div class="row d-flex align-items-center">
                                <div class="col-xl-6 col-lg-6 col-md-7 col-12">
                                    <h4>{{content.feature_title}}</h4>
                                    <ul class="blog-list">
                                        <li class="text-white" v-for="item in content.features" :key="item.id"><i class="las la-check"></i>{{item.feature}} </li>
                                    
                                    </ul>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-5 col-12">
                                    <div class="list-item-bg">
                                        <img :src="content.feature_image" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="blog-content-two">
                            <h3>{{content.heading2}}</h3>
                            <p>{{content.heading2_para1}}
                            </p>                            
                        </div>
                        <div class="row d-flex align-items-center mt-50 mb-100">
                            <div class="col-xl-6 col-lg-6 col-md-7 col-12">
                                <div class="blog-tag-wrap">
                                    <ul>
                                        <li v-for="tag in content.tags" :key="tag.id"><a href="#">{{tag.name}}</a></li>
                                       
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-5 col-12 text-end">                               
                                <div class="social-icon-wrap text-white">                        
                                    <span>Share this:</span>
                                    <a class="facebook text-white" href=""><i class="lab la-facebook-f"></i></a>
                                    <a class="dribble text-white" href=""><i class="lab la-instagram"></i></a>
                                    <a class="behance text-white" href=""><i class="lab la-twitter"></i></a>                
                                    <a class="behance text-white" href=""><i class="lab la-linkedin"></i></a>                
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="section-title">
                                    <h2>Related Post</h2>                               
                                </div>
                            </div>
                        </div>
                        <div class="row gx-5">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-12" v-for="blog in blogs.slice(0,2)" :key="blog.id">
                                <div class="single-blog-item blog-three">
                                    <div class="blog-thumb">
                                        <img :src="blog.image" alt="">
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-meta">
                                            <p class="text-white">{{blog.date}}</p>
                                        </div>
                                        <div class="blog-title">
                                            <h4 ><router-link class="text-white" :to="{ name:'BlogDetailsDark', params:{id: blog.id} }">{{blog.title}}</router-link></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                                
                        </div>
                        <div class="comments-section">
                            <h3>Comments ({{comments.length}}):</h3>
                            
                            <div class="single-comments-wrap" v-for="item in comments" :key="item.id">
                                <div class="author-thumb">
                                    <img :src="item.image" alt="">
                                </div>
                                <div class="comments-inner">
                                    <div class="comments-meta">
                                        <h4 class="author-title">{{item.name}}</h4>
                                        <p class="comments-date">{{item.date}}</p>
                                    </div>
                                    <div class="comments-content">
                                        <p>{{item.comment}}</p>
                                    </div>
                                </div>
                                <div class="reply-btn">
                                    <a href="">Reply</a>
                                </div>
                            </div>

                            
                        </div>
                        <div class="contact-form-wrap">
                            <h3>Leave A Reply</h3>
                            <div class="form">
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="text" placeholder="Your Full Name">
                                    </div>
                                    <div class="col-md-6">
                                        <input type="email" placeholder="E-mail Address">
                                    </div>
                                    <div class="col-12">
                                        <input type="text" placeholder="Subject">
                                    </div>
                                    <div class="col-12">
                                        <textarea name="message" id="message" cols="30" rows="10" placeholder="Message"></textarea>
                                    </div>
                                    <div class="col-12">
                                        <input class="main-btn" type="button" value="Post Comment">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <Footer/>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

import axios from 'axios'

export default {
  name: 'Home',
  components: {
    Navbar,Footer
  },
  data(){
    return {
        blogs : [],
        currentBlog : [],
        content : [],
        comments : []
    }
  },
  async mounted(){
        //scrolling to top of the window
    window.$('body,html').animate({scrollTop: stop}, 1000);
    window.scrollTo(0, 0);
    
    //preloader
    document.querySelector('.preloader-two').style.display = '';
    setTimeout(()=>{
        document.querySelector('.preloader-two').style.display = 'none';
    },1000);

    
    this.removeActiveClass();
    document.getElementById('blog-nav').classList.add('active');


     //Isotope Filter
	$(".port-menu li").on("click", function () {
		var selector = $(this).attr("data-filter");

		$(".port-menu li").removeClass("active");

		$(this).addClass("active");

		$(".portfolio-list").isotope({
			filter: selector,
			percentPosition: true,
		});
	});

    let fetchedBlogsData = await axios.get("/assets/data/blogs.json");
    this.blogs = fetchedBlogsData.data.blogs;
    

    this.currentBlog = this.blogs.find((item) => item.id == this.$route.params.id);
    this.content = this.currentBlog.content;
    this.comments = this.currentBlog.comments;

  },
  methods:{
    removeActiveClass(){
        var elems = document.querySelectorAll(".nav-link");

        elems.forEach(function(el) {
            el.classList.remove("active");
        });
    }
  }
}
</script>
