import { createStore } from 'vuex'

export default createStore({
  state: {
    
      home: localStorage.getItem("home")? localStorage.getItem("home") : null,
      
  
  },
  getters: {
  },
  mutations: {
    setHome(state, home) {
            state.home = home;
            
        }
  },
  actions: {
  },
  modules: {
  }
})
