<template>
    <div v-if="home == 'Home'" id="header-details" >
       <Navbar/>
    </div>
    
    <!-- Breadcrumb Area -->

    <div class="breadcroumb-area gray-bg-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="breadcroumb-title">
                        <h1 class="text-dark">{{currentBlog.title}}</h1>
                        <div class="blog-meta">
                            <p class="text-gray-2">{{currentBlog.date}} <span>Posted By {{currentBlog.author}}</span></p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

    <!-- Blog Details  -->

    <div class="single-blog-wrapper section-padding">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="blog-content-wrap">
                        <div class="blog-thumb">
                            <img :src="content.image" alt="">
                        </div>
                        <p class="text-gray">{{content.para1}}
                        </p>


                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

import axios from 'axios'

export default {
  name: 'Home',
  components: {
    Navbar,Footer
  },
  data(){
    return {
        blogs : [],
        currentBlog : [],
        content : [],
        comments : [],
        home : ''
    }
  },

  async mounted(){
    //scrolling to top of the window
    window.$('body,html').animate({scrollTop: stop}, 1000);
    window.scrollTo(0, 0);

    //preloader
    document.querySelector('.preloader-two').style.display = '';
    setTimeout(()=>{
        document.querySelector('.preloader-two').style.display = 'none';
    },1000);
    
   //removing active class from all nav link and adding active class to clicked nav link
    setTimeout(() => {
        this.removeActiveClass();
        document.getElementById('blog-nav').classList.add('active');
    }, 500);
    


    //getting active home from vue-store
    this.home = this.$store.state.home;
    

    //Isotope Filter
	$(".port-menu li").on("click", function () {
		var selector = $(this).attr("data-filter");

		$(".port-menu li").removeClass("active");

		$(this).addClass("active");

		$(".portfolio-list").isotope({
			filter: selector,
			percentPosition: true,
		});
	});

    //sticky nav
    $(window).scroll(function(){
            if ($(this).scrollTop() > 90) {
            $('#header-details').addClass('sticky-top');
            } else {
            $('#header-details').removeClass('sticky-top');
            }
        });

    let fetchedBlogsData = await axios.get("/assets/data/blogs.json");
    this.blogs = fetchedBlogsData.data.blogs;
   

    this.currentBlog = this.blogs.find((item) => item.id == this.$route.params.id);
    this.content = this.currentBlog.content;
    this.comments = this.currentBlog.comments;

  },
methods:{
    removeActiveClass(){
         var elems = document.querySelectorAll(".nav-link");

        elems.forEach(function(el) {
            el.classList.remove("active");
        });
    }
  }
}
</script>
<style scoped>
.sticky-top{
    position: sticky;
    /* background: #F5F5F5; */
    top: 0;
    width: 100%;
    z-index: 99;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.nav1{
    background: #1D1730 !important;
}
</style>