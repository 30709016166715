<template>
    <footer class="footer-section dark-bg-1">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center">
                    <div class="footer-inner d-flex align-items-center justify-content-center">
                        <div class="footer-circle-wrap">
                            <img src="/assets/img/footer-circle.png" alt="">
                        </div>
                        <div class="footer-content">
                            <h3>Hello gharb</h3>
                            <p>info@gharb.tokyo</p>
                        <div class="social-area-wrap">
                            <a class="instagram" href="https://www.instagram.com/cafegharb/?hl=ja"><i class="lab la-instagram"></i></a>
                        </div>
                        </div>                        
                    </div>
                </div>
            </div>
            <div class="footer-ellipse">
                <img src="/assets/img/footer-ellipse.png" alt="">
            </div>
        </div>
        <div class="footers-shape-one">
            <img src="/assets/img/footer-shapes-1.png" alt="">
        </div>
        <div class="banner-shape-wrap">
            <div class="banner-shape-inner">
                <img src="/assets/img/shape/shaps1.png" alt="" class="shape shape1 rotate3d">
                <img src="/assets/img/shape/shaps2.png" alt="" class="shape shape2 rotate3d">
                <img src="/assets/img/shape/shaps4.png" alt="" class="shape shape4 rotate3d">
                <img src="/assets/img/shape/shaps5.png" alt="" class="shape shape5 rotate2d">
            </div>
        </div>
    </footer>

    <!-- Footer Bottom Area  -->

    <div class="footer-bottom footer-one dark-bg-1">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-12 text-center">
                    <div class="copyright-area">
                        <p class="copyright-line">© gharb, 2023 All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="footers-shape-two">
            <img src="/assets/img/footer-shapes-2.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
  name: 'Footer',
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
