<template>
    <Navbar/>
    <!-- Breadcrumb Area -->

    <div class="breadcroumb-area dark-bg-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcroumb-title">
                        <h1>Blog</h1>
                        <h6><router-link class="text-white" :to="{name:'Home1'}" >Home</router-link> / Blog</h6>
                    </div>
                </div>
            </div>
            <div class="bread-ellipse">
                <img src="/assets/img/breadcrumb-ellipse.png" alt="">
            </div>
            <div class="bread-shape">
                <img src="/assets/img/breadcrumb-shape-2.png" alt="">
            </div>
        </div>
    </div>

    <!-- Blog -->

    <div id="blog" class="blog-section blog-three section-padding dark-bg-2">
        <div class="container">            
            <div class="row">
                
                <div class="col-xl-4 col-lg-4 col-md-6 col-12" v-for="blog in blogs" :key="blog.id">
                    <div class="single-blog-item">
                        <div class="blog-thumb">
                            <img :src="blog.image" alt="">
                        </div>
                        <div class="blog-content">
                            <div class="blog-meta">
                                <p class="text-white">{{blog.date}}</p>
                            </div>
                            <div class="blog-title">
                                <h4><router-link class="text-white" :to="{ name:'BlogDetailsDark', params:{id: blog.id} }">{{blog.title}}</router-link></h4>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    

    <Footer/>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

import axios from 'axios'

export default {
    name: 'Home',
    components: {
        Navbar,Footer
    },
    data(){
        return {
            blogs : []
        }
    },
    async mounted(){
        //scrolling to top of the window
        window.$('body,html').animate({scrollTop: stop}, 1000);
        window.scrollTo(0, 0);

        //preloader
        document.querySelector('.preloader-two').style.display = '';
        setTimeout(()=>{
            document.querySelector('.preloader-two').style.display = 'none';
        },1000);

        //removing active class from all nav link and adding active class to clicked nav link
        this.removeActiveClass();
        document.getElementById('blog-nav').classList.add('active');
        
        //Isotope Filter
        $(".port-menu li").on("click", function () {
        	var selector = $(this).attr("data-filter");

        	$(".port-menu li").removeClass("active");

        	$(this).addClass("active");

        	$(".portfolio-list").isotope({
        		filter: selector,
        		percentPosition: true,
        	});
        });

        let fetchedBlogsData = await axios.get("/assets/data/blogs.json");
        this.blogs = fetchedBlogsData.data.blogs;

  },
  
  methods: {
    removeActiveClass(){
        var elems = document.querySelectorAll(".nav-link");

        elems.forEach(function(el) {
            el.classList.remove("active");
        });
    }
  }
}
</script>
