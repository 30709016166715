<template>
<div class="row">
    <div class="col-xl-12">
        <div class="testimonial-wrap ">
            <swiper
                :modules="modules"
			    :slides-per-view="1"
			    :space-between="0"
			    
			    :pagination="{ clickable: true }"
			    :autoplay="{ delay: 4000 }"
            >
            <swiper-slide v-for="review in reviews" :key="review.id">
                <div class="single-testimonial-item">
                    <div class="testimonial-content">
                        <p class="text-black">"{{review.comment}}"
                        </p>
                    </div>
                    <div class="testimonial-author">
                        <h4 class="text-black">{{review.name}}</h4>
                        <p class="text-black">{{review.designation}}</p>
                    </div>
                </div>
            </swiper-slide>

        
            </swiper>
        </div>
    </div>
</div>
</template>

<script>

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import axios from 'axios'
export default {
    name: 'ClientReviews',
    components : {Swiper, SwiperSlide,},
    data(){
        return {
            modules: [Navigation, Pagination, A11y, Autoplay],
            swiperOptions: {
                breakpoints: {
                354: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                575: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },

                990: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                },
            },
            reviews : []
        }
    },
    async mounted(){
       
        let fetchedData = await axios.get("/assets/data/customer_reviews.json");
        this.reviews = fetchedData.data.customer_reviews;

        
    }
}
</script>